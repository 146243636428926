import React from 'react';
import Button from '../../components/common/Button';
import Banner from '../../components/common/Banner';
import LiveChat from '../../components/sections/LiveChat';
import SubmitQuestion from './SubmitQuestion';
import useScrollReset from '../../hooks/useScrollReset';
import OpenGraph from '../../components/others/OpenGraph';

function Contact() {
	useScrollReset();

	return (
		<div>
			<OpenGraph
				title='Let’s work together - Contact'
				description='If you have any questions or comments, please contact us via email, phone, or drop a message on Telegram.'
			/>
			<Banner
				image='/images/hero/work-together.jpg'
				headline={
					<>
						Let’s work <br /> together
					</>
				}
				paragraph='If you have any questions or comments, please contact us via email, phone, or drop a message on Telegram. '
				button={
					<Button
						startIcon='/images/chat-dddots-fill 1.svg'
						text='Let’s chat!'
						url='https://calendly.com/stakepool247/30min'
					/>
				}
			/>
			<SubmitQuestion />
			<LiveChat />
		</div>
	);
}

export default Contact;
