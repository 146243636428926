import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import CreatePool from './views/cardano/create-pool/create-pool';
import MigratePool from './views/cardano/migrate-pool';
import PoolMonitoring from './views/cardano/pool-monitoring';
import Contact from './views/contact/Contact';
import Home from './views/home/Home';
import StakePool from './views/stake-pool/StakePool';
import UndefiendPage from './views/UndefiendPage';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const TRACKING_ID = 'UA-207729588-1'; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

function App() {
	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
		ReactGA.pageview(window.location.pathname);
	});
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='stake-pool' element={<StakePool />} />
					<Route path='contact' element={<Contact />} />
					<Route path='cardano/pool-monitoring' element={<PoolMonitoring />} />
					<Route path='cardano/create-a-new-pool' element={<CreatePool />} />
					<Route
						path='cardano/migrate-existing-pool'
						element={<MigratePool />}
					/>
					<Route path='*' element={<UndefiendPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
