import React from 'react';
import Hero from './Hero';
import LiveChat from '../../components/sections/LiveChat';
import Newsletter from '../../components/sections/Newsletter';
import Services from './Services';
import Testimonial from '../../components/sections/Testimonial';
import useScrollReset from '../../hooks/useScrollReset';
import OpenGraph from '../../components/others/OpenGraph';

function Home() {
	useScrollReset();

	return (
		<div>
			<OpenGraph
				title='Cardano Stake Pool Management Services - Home'
				description='We love running Cardano Stake Pools, but we understand that you may not share that same passion. We understand taht all you want is your Stake Pool to work without downtime. And that’s our job. We make Stake Pool Management our business, so you can focus on your goals!'
			/>
			<Hero />
			<Services />
			<Testimonial />
			<LiveChat />
			{/* <Newsletter /> */}
		</div>
	);
}

export default Home;
