import React from 'react';
import Button from '../common/Button';

function LiveChat() {
	return (
		<div className='bg-live-chat bg-cover bg-center bg-no-repeat py-20 mb-20'>
			<div className='max-w-container mx-auto flex justify-center'>
				<div className='flex flex-col items-center px-5 max-w-[90%] md:max-w-[66%]'>
					<h2 className='font-bold text-2xl md:text-3xl text-center mb-3'>
						Schedule a call/chat and see if this service is right for you.
					</h2>
					<p className='mb-10 text-black text-opacity-75'>
						There’s no commitment, pressure, or obligation.
					</p>
					<Button
						startIcon='/images/chat-dots-fill 1.svg'
						text='Free 30-min chat'
						url='https://calendly.com/stakepool247/30min'
					/>
				</div>
			</div>
		</div>
	);
}

export default LiveChat;
