import React from 'react';
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <div className="flex-grow">
      <Link to="/" className="w-[188px] block">
        <img className="w-[188px]" src="/images/Logo.svg" alt="LOGO" />
      </Link>
    </div>
  );
}

export default Logo;
