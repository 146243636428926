import React from 'react';

interface Props {
	url?: string;
	text?: string;
	startIcon?: string;
	endIcon?: string;
	className?: string;
	justifyContent?: string;

	variant?: 'contained' | 'outline';
	color?: 'warm' | 'white' | 'primary';
	onClick?: () => void;
}

function ButtonSmall(props: Props) {
	const {
		onClick,
		url,
		justifyContent,
		className,
		text,
		startIcon,
		endIcon,
		variant,
		color,
	} = props;

	// Color Styled (default primary with contained variant)
	let colorStyle = 'border-primary hover:border-opacity-90 ring-primary ';
	colorStyle +=
		variant === 'outline'
			? 'text-primary bg-transparent hover:bg-opacity-10 hover:bg-primary'
			: 'text-white bg-primary hover:bg-opacity-90';

	if (color === 'warm') {
		colorStyle =
			variant === 'outline'
				? ''
				: 'text-black bg-warm hover:bg-darkwarm border-white ring-warm';
	} else if (color === 'white') {
		colorStyle = 'border-white hover:border-opacity-90 ring-white ';
		colorStyle +=
			variant === 'outline' ? '' : 'text-primary bg-white hover:bg-opacity-90';
	}

	// Size Styled
	let sizeStyle = 'px-6 py-2 font-small text-md';

	// Final Styled
	const styled = `flex w-max gap-2 items-center ${
		justifyContent || 'justify-center'
	}  border hover:no-underline rounded-md cursor-pointer ${sizeStyle} ${colorStyle} ring-0 focus:ring-4 ring-opacity-50 duration-75`;

	const renderChild = (
		<>
			{startIcon && <img className='w-6 h-6' src={startIcon} alt='' />}
			{text && <span className='-mb-0.5'>{text}</span>}
			{endIcon && <img className='w-6 h-6' src={endIcon} alt='' />}
		</>
	);

	return url ? (
		<a href={url} className={`${styled} ${className}`}>
			{renderChild}
		</a>
	) : (
		<button
			type='button'
			onClick={onClick}
			className={`${styled} ${className}`}
		>
			{renderChild}
		</button>
	);
}

export default ButtonSmall;
