import React from 'react';
import { Link } from 'react-router-dom';
import type { To } from 'react-router-dom';

interface Props {
  match: boolean;
  name: string;
  url: To;
  toggleDrawer: () => void;
  clickable: boolean;
  onClick: () => void;
}

function Item({ match, name, url, onClick, toggleDrawer, clickable }: Props) {
  return (
    <div
      className="flex gap-2 items-center"
      onClick={() => {
        toggleDrawer();
        if (clickable) {
          onClick();
        }
      }}
    >
      <img
        className={`w-1.5 h-1.5 rounded-full ${match ? 'visible' : 'invisible'}`}
        src="/images/Active.svg"
        alt=""
      />
      {!clickable && (
        <Link
          className={`${
            match ? 'text-primary' : 'text-black'
          } hover:no-underline hover:text-primary`}
          to={url}
        >
          {name}
        </Link>
      )}

      {clickable && (
        <span
          className={`${
            match ? 'text-primary' : 'text-black'
          } hover:no-underline hover:text-primary cursor-pointer`}
        >
          {name}
        </span>
      )}
    </div>
  );
}

export default Item;
