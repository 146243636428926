import React, { useState } from 'react';
import Button from '../../common/Button';
import Navlinks from './Navlinks';
import Logo from './Logo';
import useNavbarEffect from '../../../hooks/useNavbarEffect';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import Copyright from '../footer/Copyright';
import SocialConnect from '../footer/SocialConnect';
import MobileLogo from './MobileLogo';
import Scrollbars from 'react-custom-scrollbars-2';
import ButtonSmall from '../../common/ButtonSmall';

function Navbar() {
	const [drawer, setDrawer] = useState(false);
	const toggleDrawer = () => setDrawer((v) => !v);
	const navbar = useNavbarEffect('py-6 lg:py-10', 'py-4 shadow-navbar', 75);

	return (
		<nav className={`${navbar} section sticky top-0 duration-75 bg-white z-50`}>
			<div className='max-w-container mx-auto flex justify-between'>
				<Logo />
				<div className='flex gap-20 items-center'>
					<div className='hidden lg:block'>
						<Navlinks toggleDrawer={toggleDrawer} />
					</div>
					<div className='flex gap-3'>
						{/* <div className='sm:block hidden'>
							<ButtonSmall
								url='https://form.jotform.com/221791653291055'
								color='primary'
								variant='contained'
								startIcon='/images/pool-registration-24px.png'
								text='Pool Registration'
							/>
						</div> */}
						<div className='sm:block hidden'>
							<ButtonSmall
								url='https://app.stakepool247.io'
								color='warm'
								variant='contained'
								startIcon='/images/Group 1.svg'
								text='Dashboard'
							/>
						</div>
						<div className='block lg:hidden'>
							<Button
								onClick={toggleDrawer}
								color='warm'
								variant='contained'
								startIcon='/images/icons8-menu-30.svg'
							/>
							<Drawer
								style={{ width: 300 }}
								open={drawer}
								onClose={toggleDrawer}
								direction='left'
							>
								<Scrollbars>
									<div className='h-full px-4 py-6 flex flex-col justify-between'>
										<div>
											<MobileLogo toggleDrawer={toggleDrawer} />
											<div className='px-2 mb-8'>
												<Navlinks toggleDrawer={toggleDrawer} />
											</div>
											<div className='sm:hidden mb-2'>
												<ButtonSmall
													url='https://form.jotform.com/221791653291055'
													color='primary'
													justifyContent='justify-content-start'
													className='w-full lg:w-auto '
													variant='contained'
													startIcon='/images/pool-registration-24px.png'
													text='Register a Stake Pool'
												/>
											</div>
											<div className='sm:hidden mb-2'>
												<ButtonSmall
													url='https://app.stakepool247.io'
													color='warm'
													justifyContent='justify-content-start'
													className='w-full lg:w-auto '
													variant='contained'
													startIcon='/images/Group 1.svg'
													text='Dashboard'
												/>
											</div>
										</div>
										<footer className='flex flex-col gap-8 pb-4'>
											<Copyright />
											<SocialConnect />
										</footer>
									</div>
								</Scrollbars>
							</Drawer>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
