import React from 'react';
import Button from '../../components/common/Button';

function Hero() {
	return (
		<div className=''>
			<section className='relative bg-white max-w-container mx-auto py-10 min-h-[460px] bg-repeat bg-cover bg-center flex items-center justify-center rounded-md overflow-x-hidden px-5'>
				<div>
					<div className='container  mx-auto '>
						<div className='flex flex-wrap xl:items-center -mx-4 p-4 border border-black border-opacity-10'>
							<div className='w-full lg:w-1/2 px-4 mb-16 md:mb-0 '>
								<h1 className='mb-6 text-3xl md:text-5xl leading-tight font-bold tracking-tight lg:text-4xl'>
									Owning a Cardano Stake Pool hasn't been easier!
								</h1>
								<p className='mb-8 text-coolGray-500 '>
									We love running Cardano stake pools, but we understand that
									you may not share that same passion. So let our team do the
									work while you focus on what matters most to you.
								</p>
								<div className='flex flex-wrap'>
									<div className='w-full md:w-auto py-1 md:py-0 md:mr-4'>
										<div className='flex md:flex-row flex-col gap-4 items-center justify-center w-max md:w-auto mx-auto'>
											<Button
												className='w-full md:w-auto'
												url='https://t.me/stakepool247'
												color='warm'
												startIcon='/images/telegram 1.svg'
												text='Chat with us'
											/>
											<Button
												className='w-full md:w-auto'
												url='https://calendly.com/stakepool247/30min'
												variant='outline'
												color='primary'
												startIcon='/images/Group 2.svg'
												text='Schedule a call'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='w-full lg:w-1/2 lg:px-5  '>
								<div className=' relative mx-auto xl:mr-0 max-w-max '>
									<img
										className='relative rounded-7xl sm:py-5 '
										src='/images/hero/office-web.jpg'
										alt='Cardano Management Group'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Hero;
