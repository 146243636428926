import React from 'react';
import Banner from '../../../components/common/Banner';
import useScrollReset from '../../../hooks/useScrollReset';
import OpenGraph from '../../../components/others/OpenGraph';
import Button from '../../../components/common/Button';
import ManagementServices from './management-services';
import Dashboard from './dashboard';
import Divider from '../../../components/common/Divider';

function CreatePool() {
	useScrollReset();

	return (
		<div>
			<OpenGraph
				title='Launch a Cardano Stake Pool'
				description='When it comes to running a Stake Pool, we’ve got you covered. We offer Stake Pool as a Service, which means that we will manage your Stake Pool for you. We will make sure that it is always running smoothly and that there is no downtime. All you have to do is sit back and relax – we will take care of everything for you!'
			/>
			<Banner
				image='/images/hero/stakepool247-background.jpg'
				headline={<>Launch a Cardano Stake Pool</>}
				paragraph='When it comes to running a Stake Pool, we’ve got you covered. We offer Stake Pool as a Service, which means that we will manage your Stake Pool for you. We will make sure that it is always running smoothly and that there is no downtime. All you have to do is sit back and relax – we will take care of everything for you!'
				button={
					<div className='flex md:flex-row flex-col gap-4 items-center justify-center w-max md:w-auto mx-auto'>
						<Button
							startIcon='/images/chat-dddots-fill 1.svg'
							text='Book a meeting!'
							url='https://calendly.com/stakepool247/30min'
							color='warm'
						/>
						<Button
							startIcon='/images/chat-dddots-fill 1.svg'
							text='Request a Quote'
							url='https://form.jotform.com/222091653085353'
							color='primary'
						/>
					</div>
				}
			/>
			<Divider />
			<ManagementServices />
			<Divider />
			<Dashboard />
		</div>
	);
}

export default CreatePool;
