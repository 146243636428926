import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';

const items = [
	{
		id: 3948,
		icon: '/images/Mask group.svg',
		title: 'Cardano Stake Pool Management Service',
		learnmore: '#',
		url: 'cardano/create-a-new-pool',
	},
	{
		id: 56767,
		icon: '/images/Mask group34.svg',
		title: 'Cardano Stake Pool Migration',
		learnmore: '#',
		url: 'cardano/migrate-existing-pool',
	},
	{
		id: 8987,
		icon: '/images/i7239887532l.svg',
		title: 'Cardano Monitoring solution (under development)',
		learnmore: '#',
		url: 'cardano/pool-monitoring',
	},
];

function Services() {
	return (
		<div className='section overflow-x-hidden'>
			<div className='bg-servicepattarn bg-center bg-no-repeat bg-cover py-[80px]'>
				<div className='max-w-container mx-auto'>
					<h2 className='font-bold text-2xl text-center mb-10'>
						Our services that make things easier
					</h2>
					<div className='grid md:grid-cols-3 gap-6'>
						{items.map(({ id, icon, title, url, learnmore }) => (
							<div
								key={id}
								className='text-center flex flex-col justify-between items-center py-6 px-4 border border-black hover:border-primary border-opacity-20 shadow-service-card hover:shadow-hover-service-card bg-white rounded-lg cursor-pointer duration-100'
							>
								<div>
									<img
										className='mb-6 inline-block mx-auto'
										src={icon}
										alt={title}
									/>
									<h4 className='mb-10 font-semibold text-lg'>{title}</h4>
								</div>
								<Link to={url}>
									<Button
										url={learnmore}
										variant='outline'
										text='Learn More'
										endIcon='/images/Arrow Top Right 1 - 192x192.svg'
									/>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Services;
