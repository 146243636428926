const cards = [
	{
		id: 23423,
		thumb: '/images/community-support.jpg',
		title: 'Launch your pool using community support',
		paragraph: (
			<>
				If you want to try your hand at staking on the Cardano network, or you
				have good technical knowledge and are interested in building your own
				stake pool, we have created an installation guide for you. <br /> <br />{' '}
				By following our guide, you will be able to launch your own Cardano
				stake pool. And if you're just interested in exploring, you can use the
				Cardano TestNet for free without any worries. <br /> <br />
				So if you're ready to take the plunge and learn how to stake on Cardano,
				follow our guide and get started today!
			</>
		),
		action: {
			text: 'Check the guide',
			url: 'https://cardano-node-installation.stakepool247.eu/',
		},
	},
	{
		id: 4646,
		thumb: '/images/cardano-outsource.jpg',
		title: 'Outsource the technical part of running a Cardnano Stake Pool.',
		paragraph: (
			<>
				If you're thinking about running a Cardano stake pool, but don't want to
				deal with the technical aspects, you can outsource that part to us.
				We'll make sure your stake pool is up and running smoothly, so you can
				focus on other things. Contact us today to learn more!
			</>
		),
		action: {
			text: 'Contact us',
			url: '/contact',
		},
	},
];

export default cards;
