import React from 'react';
import Logo from './Logo';

function MobileLogo({ toggleDrawer }: { toggleDrawer: () => void }) {
  return (
    <div className="lg:mb-0 mb-10 flex items-center gap-2 justify-between">
      <Logo />
      <button
        type="button"
        className="px-3 py-2 focus:bg-warm duration-100 rounded-md"
        onClick={toggleDrawer}
      >
        <img className="w-6 h-6" src="/images/icons8-close.svg" alt="" />
      </button>
    </div>
  );
}

export default MobileLogo;
