import React from 'react';
import contactInfo from '../../helpers/contact-info.json';

function SubmitQuestion() {
  return (
    <div className="mb-[120px]">
      <div className="max-w-container mx-auto">
        <div className="grid px-6 md:grid-cols-5 gap-8">
          <div className="md:col-span-3 flex flex-col justify-center">
            <h4 className="font-bold text-2xl mb-4">Any questions? Drop a message!</h4>
            <p>
              Let us know if you have any questions regarding running your pool on Cardano! We have
              vast experience in running Cardano Stake Pools on Cardano and let’s chat and figure
              out which solution will be the best one for you!
            </p>
          </div>
          <div className="md:col-span-2 flex flex-col justify-center">
            <div className="bg-contact-info p-10 bg-cover bg-center bg-no-repeat">
              <ul className="list-none flex flex-col gap-6">
                {contactInfo.map(({ id, title, icon, info, anchor }) => (
                  <li key={id}>
                    <h5 className="font-semibold mb-2">{title}</h5>
                    <div className="flex gap-2 items-center">
                      <img className="w-6 h-6" src={icon} alt="" />
                      <a href={anchor} className="text-black">
                        <span>{info}</span>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitQuestion;
