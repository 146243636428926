import React from 'react';
import Button from '../../components/common/Button';
import cards from './articlesData';

function Articals() {
  return (
    <div>
      <div className="max-w-container mx-auto mb-[120px]">
        <div className="flex flex-col gap-20 px-6">
          {cards.map(({ id, thumb, title, paragraph, action: { text, url } }, i) => (
            <div
              className={`flex items-center gap-11 ${
                i % 2 === 0 ? 'md:flex-row flex-col' : 'md:flex-row-reverse flex-col'
              }`}
              key={id}
            >
              <div className="md:w-1/2">
                <img className="w-full rounded-md drop-shadow-article" src={thumb} alt={title} />
              </div>
              <div className="md:w-1/2">
                <h3 className="font-bold text-2xl mb-4">{title}</h3>
                <p className="mb-10">{paragraph}</p>
                <div>
                  <Button
                    url={url}
                    text={text}
                    variant="outline"
                    className="w-full md:w-auto"
                    endIcon="/images/sdfdArrow Top Right 1 - 192x192.svg"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Articals;
