import React from 'react';

function Dashboard() {
	return (
		<>
			<div className='md:max-w-4xl mx-auto text-center pb-15 mb-12 '>
				<h1 className='mb-4 text-3xl md:text-4xl leading-tight font-bold tracking-tighter'>
					Cardano Stake Pool Dashboard
				</h1>
				<p className='text-lg md:text-xl text-coolGray-500 font-medium'>
					See all the important information about your stake pool's performance.
				</p>

				<section className='relative bg-coolGray-50  max-w-container mx-auto py-10 min-h-[460px] bg-repeat bg-cover bg-center flex items-center justify-center rounded-md mb-20 mt-12'>
					<div className='container px-4 mx-auto'>
						<div className='flex flex-wrap -mx-4'>
							<div className='w-full md:w-1/2 px-4 mb-16 md:mb-0'>
								<div className='relative mx-auto md:ml-0 max-w-max'>
									<img
										className='absolute z-10 -left-10 -bottom-8 w-28 md:w-auto'
										src='flex-ui-assets/elements/dots3-blue.svg'
										alt=''
									/>
									<img
										src='https://static.shuffle.dev/uploads/files/53/5349064dfe6709592a54c86b6d79d7e39781871f/CleanShot-2022-07-25-at-15-18-19.jpg'
										alt=''
									/>
								</div>
							</div>
							<div className='w-full md:w-1/2 px-4'>
								<div className='flex flex-wrap -mx-4 text-center md:text-left'>
									<div className='w-full md:w-1/2 px-4 mb-8'>
										<div className='inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-primary font-semibold rounded-full'>
											1
										</div>
										<h3 className='mb-2 text-xl font-bold'>Pool's analytics</h3>
										<p className='font-medium text-coolGray-500 text-justify'>
											See the Delegation size, rewards and other key facts about
											your pool
										</p>
									</div>
									<div className='w-full md:w-1/2 px-4 mb-8'>
										<div className='inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-primary font-semibold rounded-full'>
											2
										</div>
										<h3 className='mb-2 text-xl font-bold'>Block Schedule</h3>
										<p className='font-medium text-coolGray-500 text-justify'>
											You can always see the Block Producing Schedule
											(Leadership logs)&nbsp;
										</p>
									</div>
									<div className='w-full md:w-1/2 px-4 mb-8'>
										<div className='inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-primary font-semibold rounded-full'>
											3
										</div>
										<h3 className='mb-2 text-xl font-bold'>Easy Management</h3>
										<p className='font-medium text-coolGray-500 text-justify'>
											We are developing a dashboars which will help you to
											manage your pool without any technical knowledge.
										</p>
									</div>
									<div className='w-full md:w-1/2 px-4'>
										<div className='inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-primary font-semibold rounded-full'>
											4
										</div>
										<h3 className='mb-2 text-xl font-bold'>
											See your pool's performace
										</h3>
										<p className='font-medium text-coolGray-500 text-justify'>
											You can check the pools block producing performance as we
											are registrating all the upcomong&nbsp; blocks and
											checking/updating it's status.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}

export default Dashboard;
