import React from 'react';
import Banner from '../../components/common/Banner';
import useScrollReset from '../../hooks/useScrollReset';
import OpenGraph from '../../components/others/OpenGraph';
import Button from '../../components/common/Button';

function PoolMonitoring() {
	useScrollReset();

	return (
		<div>
			<OpenGraph
				title='Let’s work together - Contact'
				description='If you have any questions or comments, please contact us via email, phone, or drop a message on Telegram.'
			/>
			<Banner
				image='/images/hero/coming-soon.jpg'
				headline={<></>}
				paragraph='We are still working on this... if you have any questions / comments / suggestions, please reach out to us! '
				button={
					<Button
						startIcon='/images/chat-dddots-fill 1.svg'
						text='Let’s chat!'
						url='https://calendly.com/stakepool247/30min'
					/>
				}
			/>
		</div>
	);
}

export default PoolMonitoring;
