import React from 'react';
import { Link } from 'react-router-dom';
import type { To } from 'react-router-dom';

interface Props {
  toggleDropdown: (v: boolean) => void;
  url: To;
  name: string;
  toggleDrawer: () => void;
}

function MobileItem({ toggleDropdown, toggleDrawer, url, name }: Props) {
  return (
    <li
      className="border-b border-smoke last:border-b-0 group"
      onClick={() => {
        toggleDropdown(false);
        toggleDrawer();
      }}
    >
      <Link
        to={url}
        className="py-3 px-4 text-black block duration-75 hover:bg-primary hover:text-white hover:no-underline group-first:rounded-t-md group-last:rounded-b-md"
      >
        {name}
      </Link>
    </li>
  );
}

export default MobileItem;
