import React from 'react';

function Copyright() {
	const currentYear = new Date().getFullYear();

	return (
		<div>
			<h4 className='text-primary text-sm md:text-md font-bold md:mb-4'>
				Cardano Stake Pool Services
			</h4>
			<p className='text-black text-opacity-50'>
				&copy; {currentYear} StakePool247. All Rights Reserved.
			</p>
		</div>
	);
}

export default Copyright;
