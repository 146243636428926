import React from 'react';
import Copyright from './Copyright';
import SocialConnect from './SocialConnect';

function Footer() {
  return (
    <footer className="max-w-container mx-auto mb-10 section">
      <div className="flex sm:flex-row flex-col gap-6 justify-between">
        <Copyright />
        <SocialConnect />
      </div>
    </footer>
  );
}

export default Footer;
