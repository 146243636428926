import React from 'react';
import OpenGraph from '../components/others/OpenGraph';

function UndefiendPage() {
  return (
    <div>
      <OpenGraph title="404 - PAGE NOT FOUND" description="Unavailable this page" />
      <div className="min-h-[600px] max-w-container mx-auto flex justify-center items-center bg-servicepattarn mb-20">
        <div className="text-center flex flex-col">
          <h2 className="text-[160px] leading-[1] text-black text-opacity-95 font-black">404</h2>
          <p className="text-[34px] text-black text-opacity-90 uppercase leading-[1] font-semibold">
            Page Not Found
          </p>
        </div>
      </div>
    </div>
  );
}

export default UndefiendPage;
