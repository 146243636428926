import React from 'react';
import socials from '../../../helpers/socials.json';

function SocialConnect() {
	return (
		<div>
			<p className='text-black text-opacity-75 mb-4'>Connect with us</p>
			<div className='flex gap-3 flex-wrap'>
				{socials.map(({ id, url, icon }) => (
					<a
						key={id}
						href={url}
						target='_blank'
						className='hover:scale-110 duration-75 active:scale-90'
						rel='noreferrer'
					>
						<img src={icon} className='w-6 h-6' alt='' />
					</a>
				))}
			</div>
		</div>
	);
}

export default SocialConnect;
