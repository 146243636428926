import React from 'react';
import Button from '../../components/common/Button';
import Banner from '../../components/common/Banner';
import LiveChat from '../../components/sections/LiveChat';
import Newsletter from '../../components/sections/Newsletter';
import Testimonial from '../../components/sections/Testimonial';
import Articals from './Articals';
import useScrollReset from '../../hooks/useScrollReset';
import OpenGraph from '../../components/others/OpenGraph';

function StakePool() {
	useScrollReset();

	return (
		<div>
			<OpenGraph
				title='Run your own Cardano Stake Pool - Stake Pool'
				description='Our goal is to help you to run your own CARDANO STAKE POOL! We have created a guide on how to build your own stake pool from scratch, which is available for free! For those who are buse or lack the experience to run a pool - we have a full IT Stake Pool Management solution for you!'
			/>
			<Banner
				image='/images/hero/cardano-stake-pool.jpg'
				headline='Run your own Cardano Stake Pool'
				paragraph='Our goal is to help you to run your own CARDANO STAKE POOL! We have created a guide on how to build your own stake pool from scratch, which is available for free! For those who are busy or lack the experience to run a pool - we have a full IT Stake Pool Management solution for you!'
				button={
					<Button
						startIcon='/images/chat-dddots-fill 1.svg'
						text='Chat with us'
					/>
				}
			/>
			<Articals />

			<Testimonial />
			<LiveChat />
			{/* <Newsletter /> */}
		</div>
	);
}

export default StakePool;
