import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import navlinks from '../../../helpers/navlinks.json';
import Item from './Item';
import MobileItem from './MobileItem';

function Navlinks({ toggleDrawer }: { toggleDrawer: () => void }) {
  const location = useLocation();
  const path = location.pathname;
  const [dropdown, setDropdown] = useState<boolean | number>(false);
  const toggleDropdown = (index: number | boolean) => setDropdown(index);

  return (
    <ul className="list-none flex lg:flex-row flex-col gap-3">
      {navlinks.map(({ id, name, url, lists }, i) => {
        let match = false;
        if (i === 0) {
          match = path === url;
        } else {
          match = path.indexOf(url) >= 0;
        }
        const detectLists = lists?.length && lists?.length > 0 ? true : false;

        return (
          <li key={id} className="flex gap-1 items-center relative">
            <Item
              clickable={detectLists}
              toggleDrawer={toggleDrawer}
              match={match}
              name={name}
              url={url}
              onClick={() => toggleDropdown(id)}
            />
            {detectLists && (
              <button type="button" onClick={() => toggleDropdown(id)}>
                <img
                  className={`w-6 h-6 duration-75 ${dropdown ? 'rotate-180' : ''}`}
                  src="/images/chevron-down.svg"
                  alt=""
                />
              </button>
            )}
            {detectLists && dropdown === id && (
              <div className="absolute top-10 right-0 z-10 ">
                <div className="border-muted border bg-white rounded-md drop-shadow-subitems w-max shadow-md relative z-20">
                  <ul className="list-none flex flex-col">
                    {lists?.map(({ id, name, url }) => (
                      <MobileItem
                        toggleDrawer={toggleDrawer}
                        key={id}
                        name={name}
                        toggleDropdown={toggleDropdown}
                        url={url}
                      />
                    ))}
                  </ul>
                </div>
                <div
                  onClick={() => toggleDropdown(false)}
                  className="fixed top-0 right-0 bottom-0 left-0 bg-opacity-0 bg-black z-10"
                />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default Navlinks;
