import React from 'react';

interface Props {
	image: string;
	headline: string | JSX.Element;
	paragraph: string;
	button: JSX.Element;
}

function Banner({ headline, paragraph, button, image }: Props) {
	return (
		<div className='mb-[100px]'>
			<div
				className='max-w-container mx-auto bg-center bg-no-repeat bg-cover rounded-md px-6 md:px-12 py-28 md:py-48'
				style={{ backgroundImage: `url("${image}")` }}
			>
				<div className='grid md:grid-cols-6 gap-14 md:gap-24'>
					<div className='md:col-span-2'>
						<h1 className='font-bold text-3xl text-white text-center md:text-left'>
							{headline}
						</h1>
					</div>
					<div className='md:col-span-3'>
						<p className='text-white text-opacity-100 mb-6 text-center md:text-left bg-black bg-opacity-40'>
							{paragraph}
						</p>
						<div className='flex justify-center md:justify-center'>
							{button}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Banner;
