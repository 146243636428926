import React from 'react';

function Testimonial() {
  return (
    <div className="bg-testimonial bg-no-repeat bg-cover bg-center py-20 max-w-container mx-auto mb-[120px]">
      <div className="flex flex-col gap-6 items-center">
        <img className="w-10 h-10" src="/images/985934dfml.svg" alt="" />
        <blockquote className="px-6 max-w-[90%] md:max-w-[66%]">
          <p className="font-medium text-xl text-center mb-6">
            I can focus now more on attracting delegators and sleep peacefully, as I know that my
            Cardano Stake pool is managed by professionals
          </p>
          <footer className="text-black flex justify-center items-center gap-3 text-opacity-75 text-center">
            <img className="w-4 h-0.5" src="/images/Linesalfkj.svg" alt="" />
            Natalia
          </footer>
        </blockquote>
      </div>
    </div>
  );
}

export default Testimonial;
