import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  title: string;
  type?: string;
  image?: string;
  description?: string;
  url?: string;
  site_name?: string;
  locale?: string;
}

function OpenGraph({ title, type, image, description, url, site_name, locale }: Props) {
  const defaultType = type || 'website';

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        {type && <meta property="og:type" content={defaultType} />}
        {image && <meta property="og:image" content={image} />}
        {description && <meta property="og:description" content={description} />}
        {url && <meta property="og:url" content={url} />}
        {site_name && <meta property="og:site_name" content={site_name} />}
        {locale && <meta property="og:locale" content={locale} />}
      </Helmet>
    </div>
  );
}

export default OpenGraph;
